<template>
  <div id="itemlist">
                <div class="input-group">
                    <span class="input-group-text">縣市</span>
                    <select
                      v-model="twcity"
                      @change="oncity()"
                      class="form-select"
                      aria-label="First name"
                    >
                    <option v-for="list in mytw" :value="list.city" :key="list.id">{{list.city}}</option>
                    </select>     
                    <span class="input-group-text">鄉鎮</span>
                    <select
                      v-model="twvillage"  
                      @change="onvillage()"
                      class="form-select"
                      aria-label="First name"
                    >
                      <option
                        v-for="list in twvillages"
                        :value="list.village"
                        :key="list.village"
                      >{{list.village}}</option>
                    </select> 
                    <span class="input-group-text">村里</span>
                    <select
                      v-model="twtown"
                      @change="ontwtown()"
                      class="form-select"
                      aria-label="First name"
                    >
                      <option v-for="list in twtowns" :value="list.town" :key="list.town">{{list.town}}</option>
                    </select>   
                </div>                       
  </div>
</template>

<script>
import { mapState } from "vuex";
import * as moment from "moment/moment";
import TWdata from "../assets/json/twdata.json";
import TW from "../assets/json/tw.json";
export default {
  name: 'Addresslist',
  props: {
    msg: String,
    item: Array,
    winsize: Object,
  },
  data() {
    return {
      myJson: TWdata,
      mytw: TW,
      address:'',
      twcity: "",
      twtown: "",
      twvillage: "",
      twvillages: [],
      twtowns: [],      
      window: {
            width: 0,
            height: 0
        }   
    }
  }, 
  computed: {
    ...mapState("account", ["status", "user"]),
  },  
  methods: {
    fdateformat(val){
        return moment(val.sdate).format("YYYY-MM-DD HH:mm") +'~'+ moment(val.edate).format("MM-DD HH:mm") ; 
    },
    catImg(imgs) {
            return require(`../assets/img/${imgs}`);
    }, 
    getxt(val){
        return val.substring(1, 32)
    },  
    groupBy(array, groups, valueKey) {
      var map = new Map();
      groups = [].concat(groups);
      return array.reduce((r, o) => {
        groups.reduce((m, k, i, { length }) => {
          var child;
          if (m.has(o[k])) return m.get(o[k]);
          if (i + 1 === length) {
            child = Object.assign(...groups.map(k => ({ [k]: o[k] })), {
              [valueKey]: 0
            });
            r.push(child);
          } else {
            child = new Map();
          }
          m.set(o[k], child);
          return child;
        }, map)[valueKey] += +o[valueKey];
        return r;
      }, []);
    },

    getaddress(){
      let city = this.twcity==undefined ? '':this.twcity
      let village = this.twvillage==undefined ? '':this.twvillage
      let town = this.twtown==undefined ? '':this.twtown
      this.address = city +''+ village +''+ town
      this.$emit("toaddress",this.address,city,village,town);
    },
    oncity() {
      this.twvillages = this.groupBy(
        this.myJson.filter(el => {
          return el.city === this.twcity;
        }),
        "village"
      );    
      this.twvillage='' 
      this.twtown=''
      this.twtowns = [];
      this.getaddress()
      //groupBy(data, 'Phase', 'Value')
    },

    onvillage() {
      this.twtowns = this.groupBy(
        this.myJson.filter(el => {
          return el.village === this.twvillage;
        }),
        "town"
      );
      this.twtown=''
      this.getaddress()
    },
    ontwtown(){
      this.getaddress()
    },     
  },   
  created() {
  }, 
  
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#itemlist {
        margin:0px auto;
        padding:0px;
        max-width: 1024px;    
 }
 .form-select {
   max-width: 130px;
 }

</style>
